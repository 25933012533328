import React, { type CSSProperties } from 'react';

import { createTheme } from '@mui/material/styles';

import { makeColorScheme } from './colorScheme';
import useThemeMode from './useThemeMode';

const APP_BACKGROUND = '#F0F0F0';

const defaultTheme = createTheme();

const makeCustomThemeOptions = (colorScheme: ReturnType<typeof makeColorScheme>) =>
  createTheme({
    shape: {
      borderRadius: 14,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        mobile: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      // this is Notion's preset (2022)
      fontFamily: `ui-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol'`,
    },
    mixins: {
      toolbar: {
        minHeight: 48,
        height: 48,
      },
      appBar: {
        height: 48,
      },
      secondaryNavContainer: {
        maxWidth: 320,
        marginLeft: 'auto',
        marginBottom: defaultTheme.spacing(1),
        borderRadius: defaultTheme.shape.borderRadius,
        border: `1px solid ${defaultTheme.palette.divider}`,
        [defaultTheme.breakpoints.down('sm')]: {
          maxWidth: 'unset',
          width: '100%',
          marginLeft: 0,
          marginTop: 0,
        },
      },
    },
    palette: {
      background: {
        default: APP_BACKGROUND,
        paper: colorScheme.white,
      },
      primary: {
        main: colorScheme.green,
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: colorScheme.orange,
        contrastText: '#FFFFFF',
      },
      charcoal: {
        main: colorScheme.charcoal,
        contrastText: '#FFFFFF',
      },
      graphing: colorScheme.graphing,
      location: colorScheme.location,
      plantiga: colorScheme,
      success: {
        main: colorScheme.green,
      },
    },
    components: {
      MuiAutocomplete: {
        defaultProps: {
          slotProps: {
            paper: {
              elevation: 6,
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'charcoal',
          disableElevation: true,
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          color: 'charcoal',
          disableElevation: true,
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paperAnchorLeft: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
          paperAnchorRight: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
          paperAnchorTop: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          paperAnchorBottom: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontSize: '1.1rem',
            fontWeight: 600,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          // Equivalent of '@global' in v4
          '.ais-highlight-0000000000': {
            background: colorScheme.mist,
          },
        },
      },
    },
  });

declare module '@mui/system' {
  interface Shape {
    borderRadius: number;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    mobile: true; // adds the `mobile` breakpoint
    md: true;
    lg: true;
    xl: true;
  }

  interface Palette {
    graphing: ReturnType<typeof makeColorScheme>['graphing'];
    location: ReturnType<typeof makeColorScheme>['location'];
    plantiga: ReturnType<typeof makeColorScheme>;
    charcoal: Palette['primary'];
  }

  interface PaletteOptions {
    graphing?: ReturnType<typeof makeColorScheme>['graphing'];
    location?: ReturnType<typeof makeColorScheme>['location'];
    plantiga?: ReturnType<typeof makeColorScheme>;
    charcoal?: PaletteOptions['primary'];
  }

  interface Mixins {
    secondaryNavContainer: CSSProperties;
    appBar: CSSProperties;
  }

  interface MixinsOptions {
    secondaryNavContainer?: CSSProperties;
    appBar?: CSSProperties;
  }

  interface Shape {
    borderRadius: number;
  }

  interface ShapeOptions {
    borderRadius: number;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    charcoal: true;
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    charcoal: true;
  }
}

export function usePlantigaTheme(overrideVersion?: ReturnType<typeof useThemeMode>['version']) {
  const { version } = useThemeMode();

  const plantigaTheme = React.useMemo(
    () => makeCustomThemeOptions(makeColorScheme(overrideVersion ?? version)),
    [overrideVersion, version],
  );

  return React.useMemo(() => createTheme(plantigaTheme), [plantigaTheme]);
}

export default createTheme(makeCustomThemeOptions(makeColorScheme('v2')));

/**
 * Do not modify
 * @generated by src/framework/vite-plugin-urls.ts
 */
/* eslint-disable */

// prettier-ignore
export default {
  "404": "/*",
  "acceptInvite": "/account/accept-invite",
  "activities": "/org/:teamId/activity/:activityId/:page?",
  "activity": "/org/:teamId/people/:athleteId/daily/:day/activity/:activityId/",
  "activityExplore": "/org/:teamId/people/:athleteId/daily/:day/activity/:activityId/explore",
  "activityFiles": "/org/:teamId/people/:athleteId/daily/:day/activity/:activityId/files",
  "activityInfo": "/org/:teamId/people/:athleteId/daily/:day/activity/:activityId/info",
  "activityLabels": "/org/:teamId/people/:athleteId/daily/:day/activity/:activityId/labels",
  "activityReport": "/org/:teamId/people/:athleteId/daily/:day/activity/:activityId/report",
  "activityRoot": "/org/:teamId/people/:athleteId/daily/:day/activity/:activityId?/:page?",
  "activityRootDeprecated": "/org/:teamId/people/:athleteId/activity/:activityId/:page?",
  "activityStatsForNerds": "/org/:teamId/people/:athleteId/daily/:day/activity/:activityId/stats-for-nerds",
  "athlete": "/org/:teamId/people/:athleteId/",
  "athleteActivities": "/org/:teamId/people/:athleteId/activity/:activityId/",
  "athleteActivityExplore": "/org/:teamId/people/:athleteId/activity/:activityId/explore",
  "athleteActivityFiles": "/org/:teamId/people/:athleteId/activity/:activityId/files",
  "athleteActivityInfo": "/org/:teamId/people/:athleteId/activity/:activityId/info",
  "athleteActivityLabels": "/org/:teamId/people/:athleteId/activity/:activityId/labels",
  "athleteActivityReport": "/org/:teamId/people/:athleteId/activity/:activityId/report",
  "athleteActivitySummaries": "/org/:teamId/people/:athleteId/activity/:activityId/summaries",
  "athleteDaily": "/org/:teamId/people/:athleteId/daily/",
  "athleteDailyActivityDay": "/org/:teamId/people/:athleteId/daily/:day/activityType/:type",
  "athleteDailyDay": "/org/:teamId/people/:athleteId/daily/:day/",
  "athleteLoadOverview": "/org/:teamId/people/:athleteId/load-overview",
  "athleteMultiActivityReport": "/org/:teamId/people/:athleteId/daily/:day/activity/activity-report/:templateId",
  "athleteProfile": "/org/:teamId/people/:athleteId/profile/",
  "athleteProfileEvents": "/org/:teamId/people/:athleteId/profile/events",
  "athleteProfileFiles": "/org/:teamId/people/:athleteId/profile/files",
  "athleteProfileInfo": "/org/:teamId/people/:athleteId/profile/info",
  "athleteProfileLabels": "/org/:teamId/people/:athleteId/profile/labels",
  "athleteRedirectByName": "/org/:teamId/people/name/:name",
  "athletes": "/org/:teamId/people/",
  "athleteTimeline": "/org/:teamId/people/:athleteId/trends",
  "currentTeam": "/current-org/*",
  "demoSignin": "/account/demo-signin",
  "error": "/error/",
  "errorAsync": "/error/async",
  "errorFirestore": "/error/firestore",
  "errorTimeout": "/error/timeout",
  "measures": "/util/measures",
  "newTeam": "/new-org",
  "passwordReset": "/account/password-reset",
  "paymentSuccess": "/org/:teamId/purchase/success",
  "purchase": "/org/:teamId/purchase/",
  "record": "/org/:teamId/record/",
  "recordActive": "/org/:teamId/record/:activityTypeId/recording/:swid",
  "recordFinalize": "/org/:teamId/record/:activityTypeId/finalize/:swid",
  "shippingInfo": "/org/:teamId/purchase/shipping-info",
  "signin": "/account/signin",
  "signup": "/account/signup",
  "teamActivityTypes": "/org/:teamId/profile/activity-types",
  "teamDevices": "/org/:teamId/devices",
  "teamDocks": "/org/:teamId/devices/docks",
  "teamExport": "/org/:teamId/export",
  "teamFeed": "/org/:teamId/feed",
  "teamLanding": "/org/:teamId/",
  "teamPods": "/org/:teamId/devices/pods",
  "teamPreferences": "/org/:teamId/profile/preferences",
  "teamProfile": "/org/:teamId/profile/",
  "teamTimeline": "/org/:teamId/profile/timeline",
  "teamUsers": "/org/:teamId/profile/users",
  "timeseries": "/util/timeseries",
  "typography": "/util/typography",
  "welcome": "/",
} as const;

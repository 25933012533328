/**
 * Do not modify
 * @generated by src/framework/vite-plugin-urls.ts
 */
/* eslint-disable */

// ignore unused exports
import { type RouteObject } from 'react-router';

type RouteModule = {
  default: NonNullable<RouteObject['Component']>;
  clientLoader?: RouteObject['loader'];
};
async function leafModule(load: () => Promise<RouteModule>) {
  const { default: Component, clientLoader, ...rest } = await load();

  return {
    Component,
    loader: clientLoader,
    ...rest,
  };
}
// prettier-ignore
export default [
  {
    path: "*",
    lazy: async () => leafModule(() => import("./routes/$")),
  },

  {
    lazy: async () => leafModule(() => import("./routes/_auth")),
    children: [
      {
        index: true,
        lazy: async () => leafModule(() => import("./routes/_auth._index")),
      },
      {
        path: "current-org/*",
        lazy: async () => leafModule(() => import("./routes/_auth.current-org.$")),
      },
      {
        path: "new-org",
        lazy: async () => leafModule(() => import("./routes/_auth.new-org")),
      },
      {
        path: "org/:teamId",
        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId")),
        children: [
          {
            index: true,
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId._index")),
          },
          {
            path: "activity/:activityId/:page?",
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.activity.$activityId.($page)")),
          },
          {
            path: "devices",
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.devices")),
            children: [
              {
                index: true,
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.devices._index")),
              },
              {
                path: "docks",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.devices.docks")),
              },
              {
                path: "pods",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.devices.pods")),
              },
            ]
          },
          {
            path: "export",
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.export")),
          },
          {
            path: "feed",
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.feed")),
          },
          {
            path: "people",
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people")),
            children: [
              {
                path: ":athleteId",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId")),
                children: [
                  {
                    index: true,
                    lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId._index")),
                  },
                  {
                    path: "activity/:activityId",
                    lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.activity.$activityId")),
                    children: [
                      {
                        path: ":page?",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.activity.$activityId.($page)")),
                      },
                      {
                        index: true,
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.activity.$activityId._index")),
                      },
                      {
                        path: "explore",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.activity.$activityId.explore")),
                      },
                      {
                        path: "files",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.activity.$activityId.files")),
                      },
                      {
                        path: "info",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.activity.$activityId.info")),
                      },
                      {
                        path: "labels",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.activity.$activityId.labels")),
                      },
                      {
                        path: "report",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.activity.$activityId.report")),
                      },
                      {
                        path: "summaries",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.activity.$activityId.summaries")),
                      },
                    ]
                  },
                  {
                    path: "daily/:day/",
                    index: true,
                    lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day._index")),
                  },
                  {
                    path: "daily/:day/activity",
                    lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity")),
                    children: [
                      {
                        path: ":activityId",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.$activityId")),
                        children: [
                          {
                            index: true,
                            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.$activityId._index")),
                          },
                          {
                            path: "explore",
                            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.$activityId.explore")),
                          },
                          {
                            path: "files",
                            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.$activityId.files")),
                          },
                          {
                            path: "info",
                            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.$activityId.info")),
                          },
                          {
                            path: "labels",
                            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.$activityId.labels")),
                          },
                          {
                            path: "report",
                            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.$activityId.report")),
                          },
                          {
                            path: "stats-for-nerds",
                            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.$activityId.stats-for-nerds")),
                          },
                        ]
                      },
                      {
                        path: ":activityId?/:page?",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.($activityId).($page)")),
                      },
                      {
                        path: "activity-report/:templateId",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activity.activity-report.$templateId")),
                      },
                    ]
                  },
                  {
                    path: "daily/:day/activityType/:type",
                    lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily.$day.activityType.$type")),
                  },
                  {
                    path: "daily/",
                    index: true,
                    lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.daily._index")),
                  },
                  {
                    path: "load-overview",
                    lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.load-overview")),
                  },
                  {
                    path: "profile",
                    lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.profile")),
                    children: [
                      {
                        index: true,
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.profile._index")),
                      },
                      {
                        path: "events",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.profile.events")),
                      },
                      {
                        path: "files",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.profile.files")),
                      },
                      {
                        path: "info",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.profile.info")),
                      },
                      {
                        path: "labels",
                        lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.profile.labels")),
                      },
                    ]
                  },
                  {
                    path: "trends",
                    lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.$athleteId.trends")),
                  },
                ]
              },
              {
                index: true,
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people._index")),
              },
              {
                path: "name/:name",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.people.name.$name")),
              },
            ]
          },
          {
            path: "profile",
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.profile")),
            children: [
              {
                index: true,
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.profile._index")),
              },
              {
                path: "activity-types",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.profile.activity-types")),
              },
              {
                path: "preferences",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.profile.preferences")),
              },
              {
                path: "timeline",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.profile.timeline")),
              },
              {
                path: "users",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.profile.users")),
              },
            ]
          },
          {
            path: "purchase/",
            index: true,
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.purchase._index")),
          },
          {
            path: "purchase/shipping-info",
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.purchase.shipping-info")),
          },
          {
            path: "purchase/success",
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.purchase.success")),
          },
          {
            path: "record",
            lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.record")),
            children: [
              {
                path: ":activityTypeId/finalize/:swid",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.record.$activityTypeId.finalize.$swid")),
              },
              {
                path: ":activityTypeId/recording/:swid",
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.record.$activityTypeId.recording.$swid")),
              },
              {
                index: true,
                lazy: async () => leafModule(() => import("./routes/_auth.org.$teamId.record._index")),
              },
            ]
          },
        ]
      },
      {
        path: "org/",
        index: true,
        lazy: async () => leafModule(() => import("./routes/_auth.org._index")),
      },
    ]
  },

  {
    path: "account/accept-invite",
    lazy: async () => leafModule(() => import("./routes/account.accept-invite")),
  },

  {
    path: "account/demo-signin",
    lazy: async () => leafModule(() => import("./routes/account.demo-signin")),
  },

  {
    path: "account/password-reset",
    lazy: async () => leafModule(() => import("./routes/account.password-reset")),
  },

  {
    path: "account/signin",
    lazy: async () => leafModule(() => import("./routes/account.signin")),
  },

  {
    path: "account/signup",
    lazy: async () => leafModule(() => import("./routes/account.signup")),
  },

  {
    path: "error/",
    index: true,
    lazy: async () => leafModule(() => import("./routes/error._index")),
  },

  {
    path: "error/async",
    lazy: async () => leafModule(() => import("./routes/error.async")),
  },

  {
    path: "error/firestore",
    lazy: async () => leafModule(() => import("./routes/error.firestore")),
  },

  {
    path: "error/timeout",
    lazy: async () => leafModule(() => import("./routes/error.timeout")),
  },

  {
    path: "util/measures",
    lazy: async () => leafModule(() => import("./routes/util.measures")),
  },

  {
    path: "util/timeseries",
    lazy: async () => leafModule(() => import("./routes/util.timeseries")),
  },

  {
    path: "util/typography",
    lazy: async () => leafModule(() => import("./routes/util.typography")),
  },
];
